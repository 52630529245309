export const reportConstants = {
    DIARY_SALES_REQUEST: 'DIARY_SALES_REQUEST',
    DIARY_SALES_SUCCESS: 'DIARY_SALES_SUCCESS',
    DIARY_SALES_FAILURE: 'DIARY_SALES_FAILURE',

    DIARY_SALES_BY_SERVICE_REQUEST: 'DIARY_SALES_BY_SERVICE_REQUEST',
    DIARY_SALES_BY_SERVICE_SUCCESS: 'DIARY_SALES_BY_SERVICE_SUCCESS',
    DIARY_SALES_BY_SERVICE_FAILURE: 'DIARY_SALES_BY_SERVICE_FAILURE',

    MONTHLY_SALES_REQUEST: 'MONTHLY_SALES_REQUEST',
    MONTHLY_SALES_SUCCESS: 'MONTHLY_SALES_SUCCESS',
    MONTHLY_SALES_FAILURE: 'MONTHLY_SALES_FAILURE',

    SALES_BY_ITEM_REQUEST: 'SALES_BY_ITEM_REQUEST',
    SALES_BY_ITEM_SUCCESS: 'SALES_BY_ITEM_SUCCESS',
    SALES_BY_ITEM_FAILURE: 'SALES_BY_ITEM_FAILURE',

    CUSTOMERS_LOSS_REQUEST: 'CUSTOMERS_LOSS_REQUEST',
    CUSTOMERS_LOSS_SUCCESS: 'CUSTOMERS_LOSS_SUCCESS',
    CUSTOMERS_LOSS_FAILURE: 'CUSTOMERS_LOSS_FAILURE',

    PRINT_MONTH_CLOSE_REQUEST: 'PRINT_MONTH_CLOSE_REQUEST',
    PRINT_MONTH_CLOSE_SUCCESS: 'PRINT_MONTH_CLOSE_SUCCESS',
    PRINT_MONTH_CLOSE_FAILURE: 'PRINT_MONTH_CLOSE_FAILURE',

    CLEAN_REPORTS: 'CLEAN_REPORTS',

};