import { Dispatch } from "@reduxjs/toolkit";
import { alertActions } from "../../../auth/actions/alert.actions";
import { DataDialogProps } from "../../../models/data.dialog.props";
import { printPdfActions } from "../../print-pdf/actions/item.actions";
import { suscriptionConstants } from "../constants/suscription.constants";
import { Suscription } from "../models/suscription";
import { SuscriptionHistorical } from "../models/suscription-historical";
import { suscriptionService } from "../suscription.service";

export const suscriptionActions = {
    openModalSearchItem,
    save,
    addLine,
    removeLine,
    editLineQuantity,
    cleanSuscription,
    preview,
    next,
    getActive,
    selectCustomer,
    printComprobant,
    printInvoice,
    updateHistorical,
    searchByDescription,
    openModalUpdateHistorical,
    openModalPaymentMethod,
    printDiaryClose,
    openModalDiaryClose,
};

function openModalSearchItem(openSelectItem:DataDialogProps<(Suscription[])>) {
    return (dispatch: Dispatch) => {
        dispatch(  { type: suscriptionConstants.OPEN_MODAL_SEARCH_ITEM, openSelectItem });
    };
}

function openModalUpdateHistorical(openHistorical:DataDialogProps<(SuscriptionHistorical)>) {
    return {type: suscriptionConstants.OPEN_MODAL_UPDATE_HISTORICAL, openHistorical };
}
function openModalPaymentMethod(openPaymentMethod:DataDialogProps<(number)>) {
    return {type: suscriptionConstants.OPEN_MODAL_PAYMENT_METHOD, openPaymentMethod };
}
function openModalDiaryClose(openDiaryClose:DataDialogProps<(string)>) {
    return {type: suscriptionConstants.OPEN_MODAL_DIARY_CLOSE, openDiaryClose };
}

function cleanSuscription() {
    return (dispatch: Dispatch) => {
        dispatch(  { type: suscriptionConstants.CLEAN_SUSCRIPTION });
    };
}


function removeLine(suscription:Suscription ,itemId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(suscription));
        suscriptionService.removeLine(suscription, itemId)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(suscription,message));
                dispatch(alertActions.error(message));
            });
    };
    function request(suscription:Suscription) { return { type: suscriptionConstants.REMOVE_LINE_REQUEST, suscription } }
    function success(suscription:Suscription) { return { type: suscriptionConstants.REMOVE_LINE_SUCCESS, suscription } }
    function failure(suscription:Suscription, error:any) { return { type: suscriptionConstants.REMOVE_LINE_FAILURE, suscription, error } }
}
function save(suscription:Suscription) {
    return (dispatch: Dispatch) => {
        dispatch(request(suscription));
        suscriptionService.save(suscription)
            .then((result)=> { 
                    dispatch(success(result.data));
                    dispatch(alertActions.success(`Suscripción agregado exitosamente ${result.data.suscriptionId}`));
                    dispatch( printComprobant(result.data.suscriptionId!)  as any);
                },
            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(suscription,message));
                dispatch(alertActions.error(message));
              
            });
    };
    function request(suscription:Suscription) { return { type: suscriptionConstants.SAVE_REQUEST, suscription } }
    function success(suscription:Suscription) { return { type: suscriptionConstants.SAVE_SUCCESS, suscription } }
    function failure(suscription:Suscription, error:any) { return { type: suscriptionConstants.SAVE_FAILURE, suscription, error } }
}
function updateHistorical(suscription:SuscriptionHistorical) {
    return (dispatch: Dispatch) => {
        dispatch(request(suscription));
        suscriptionService.updateHistorical(suscription)
            .then((result)=> { 
                    dispatch(success(result.data));
                    dispatch(alertActions.success(`Suscripción modificada exitosamente`));
                },
                         

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(suscription,message));
                dispatch(alertActions.error(message));
              
            });
    };
    function request(historical:SuscriptionHistorical) { return { type: suscriptionConstants.UPDATE_HISTORICAL_REQUEST, historical } }
    function success(activeSuscriptions:SuscriptionHistorical[]) { return { type: suscriptionConstants.UPDATE_HISTORICAL_SUCCESS, activeSuscriptions } }
    function failure(historical:SuscriptionHistorical, error:any) { return { type: suscriptionConstants.UPDATE_HISTORICAL_FAILURE, historical, error } }
}


function addLine(suscription:Suscription ,itemId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(suscription));
        suscriptionService.addLine(suscription, itemId)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(suscription,message));
                dispatch(alertActions.error(message));
            });
    };
    function request(suscription:Suscription) { return { type: suscriptionConstants.ADD_LINE_REQUEST, suscription } }
    function success(suscription:Suscription) { return { type: suscriptionConstants.ADD_LINE_SUCCESS, suscription } }
    function failure(suscription:Suscription, error:any) { return { type: suscriptionConstants.ADD_LINE_FAILURE, suscription, error } }
}

function editLineQuantity(suscription:Suscription ,itemId: string, quantity: number) {
    return (dispatch: Dispatch) => {
        dispatch(request(suscription));
        suscriptionService.editLineQuantity(suscription, itemId, quantity)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(suscription,message));
                dispatch(alertActions.error(message));
            });
    };
    function request(suscription:Suscription) { return { type: suscriptionConstants.EDIT_LINE_QUANTITY_REQUEST, suscription } }
    function success(suscription:Suscription) { return { type: suscriptionConstants.EDIT_LINE_QUANTITY_SUCCESS, suscription } }
    function failure(suscription:Suscription, error:any) { return { type: suscriptionConstants.EDIT_LINE_QUANTITY_FAILURE, suscription, error } }
}




function next(suscriptionId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        suscriptionService.next(suscriptionId)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: suscriptionConstants.NEXT_REQUEST } }
    function success(suscription:Suscription) { return { type: suscriptionConstants.NEXT_SUCCESS, suscription } }
    function failure(error:any) { return { type: suscriptionConstants.NEXT_FAILURE,  error } }
}

function preview(suscriptionId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        suscriptionService.preview(suscriptionId)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: suscriptionConstants.PREVIEW_REQUEST } }
    function success(suscription:Suscription) { return { type: suscriptionConstants.PREVIEW_SUCCESS, suscription } }
    function failure( error:any) { return { type: suscriptionConstants.PREVIEW_FAILURE, error } }
}


function getActive() {
    return (dispatch: Dispatch) => {
        dispatch(request());
        suscriptionService.getActive()
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: suscriptionConstants.GET_ACTIVE_REQUEST } }
    function success(activeSuscriptions:Suscription[]) { return { type: suscriptionConstants.GET_ACTIVE_SUCCESS, activeSuscriptions } }
    function failure( error:any) { return { type: suscriptionConstants.GET_ACTIVE_FAILURE, error } }
}
function searchByDescription(description:string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        suscriptionService.searchByDescription(description)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: suscriptionConstants.GET_SEARCH_ACTIVE_REQUEST } }
    function success(activeSuscriptions:Suscription[]) { return { type: suscriptionConstants.GET_SEARCH_ACTIVE_SUCCESS, activeSuscriptions } }
    function failure( error:any) { return { type: suscriptionConstants.GET_SEARCH_ACTIVE_FAILURE, error } }
}
 
function selectCustomer(customerId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        suscriptionService.selectCustomer(customerId)
            .then((result)=> { 
                    dispatch(success(result.data));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: suscriptionConstants.SELECT_CUSTOMER_REQUEST } }
    function success(suscription:Suscription) { return { type: suscriptionConstants.SELECT_CUSTOMER_SUCCESS, suscription } }
    function failure(error:any) { return { type: suscriptionConstants.SELECT_CUSTOMER_FAILURE, error } }
}

function printComprobant(suscriptionId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        suscriptionService.printComprobant(suscriptionId)
            .then((result)=> { 
                    dispatch(success());
                    dispatch(printPdfActions.openPrintPdf({
                        open: true,
                        loading: false,
                        select: result.data.pdf,
                        type : 1
                    }));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: suscriptionConstants.PRINT_COMPROBANT_REQUEST } }
    function success() { return { type: suscriptionConstants.PRINT_COMPROBANT_SUCCESS } }
    function failure( error:any) { return { type: suscriptionConstants.PRINT_COMPROBANT_FAILURE, error } }
}


function printDiaryClose(date: string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        suscriptionService.printDiaryClose(date)
            .then((result)=> { 
                    dispatch(success());
                    dispatch(printPdfActions.openPrintPdf({
                        open: true,
                        loading: false,
                        select: result.data.pdf,
                        type : 1
                    }));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: suscriptionConstants.PRINT_DIARY_CLOSE_REQUEST } }
    function success() { return { type: suscriptionConstants.PRINT_DIARY_CLOSE_SUCCESS } }
    function failure( error:any) { return { type: suscriptionConstants.PRINT_DIARY_CLOSE_FAILURE, error } }
}


function printInvoice(suscriptionId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request());
        suscriptionService.printInvoice(suscriptionId)
            .then((result)=> { 
                    dispatch(success());
                    dispatch(printPdfActions.openPrintPdf({
                        open: true,
                        loading: false,
                        select: result.data.pdf,
                        type : 1
                    }));
                },

            ).catch((error)=>{
                const message =
                (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
                dispatch(failure(message));
                dispatch(alertActions.error(message));
            });
    };
    function request() { return { type: suscriptionConstants.PRINT_INVOICE_REQUEST } }
    function success() { return { type: suscriptionConstants.PRINT_INVOICE_SUCCESS } }
    function failure( error:any) { return { type: suscriptionConstants.PRINT_INVOICE_FAILURE, error } }
}