import { SuscriptionDetail } from "./suscription-detail";

export class Suscription { 
    suscriptionId?: string;
    customerId?: string;
    customer?: string;
    photoUri?:string;
    total?: number;
    subTotal?: number;
    vat?: number;
    storeId?: number;
    days?: number;
    leftDays?: number;
    companyId?: string;
    startingDate?: Date;
    endingDate?: Date;
    createdDate?: Date;
    createdBy?: number;
    detail?: SuscriptionDetail[];
    bankId?: string;
    amountCredit?: number;
    amountCash?: number;
    creditCard?:number;
    referenceCreditCard?:string;
    
    bankTransferenceId?: string;
    referenceTransference?:string;
    amountTransference?: number;
    transferenceComprobant?:string;

    comment?: string;


    constructor(datos?: Suscription) {
        if (datos != null) {
        this.suscriptionId = datos.suscriptionId;
        this.customerId = datos.customerId;
        this.customer = datos.customer;
        this.photoUri = datos.photoUri;
        this.total = datos.total;
        this.subTotal = datos.subTotal;
        this.vat = datos.vat;
        this.days = datos.days;
        this.leftDays = datos.leftDays;
        this.storeId = datos.storeId;
        this.companyId = datos.companyId;
        this.createdDate = datos.createdDate;
        this.startingDate = datos.startingDate;
        this.endingDate = datos.endingDate;
        this.createdBy = datos.createdBy;
        this.detail = datos.detail;
        this.bankId = datos.bankId;
        this.amountCredit = datos.amountCredit;
        this.amountCash = datos.amountCash;
        this.creditCard = datos.creditCard;
        this.referenceCreditCard = datos.referenceCreditCard;

        this.bankTransferenceId = datos.bankTransferenceId;
        this.referenceTransference = datos.referenceTransference;
        this.amountTransference = datos.amountTransference;
        this.transferenceComprobant = datos.transferenceComprobant;
        this.comment = datos.comment;
        return;
        }
        this.detail = [];

    }
}